<!-- @format -->

<template>
	<layout-wide>
		<sub-navbar page="Purchase Invoice" />
		<div>
			<div class="form-title">Time Interval Purchase Invoices</div>
			<b-field
				class="margin-top-20 text-red"
				style="font-size: larger; font-weight: 700"
				>Select beginning date or ending date and wait for results</b-field
			>
			<b-field class="margin-top-20" label="Select beginning date">
				<b-datepicker
					v-model="selectedBeginningDate"
					:show-week-number="showWeekNumber"
					:locale="locale"
					placeholder="Click to select..."
					icon="calendar-today"
					trap-focus
				>
				</b-datepicker>
			</b-field>
			<b-field label="Select ending date">
				<b-datepicker
					v-model="selectedEndingDate"
					:show-week-number="showWeekNumber"
					:locale="locale"
					placeholder="Click to select..."
					icon="calendar-today"
					trap-focus
				>
				</b-datepicker>
			</b-field>
		</div>
		<div
			v-if="loading"
			style="color: red; font-size: large; font-weight: 600"
			class="margin-top-20"
		>
			Getting data, please wait!
		</div>
		<div class="margin-bottom-30 margin-top-30" v-if="!loading">
			<div class="margin-bottom-30 text-bold text-blue">
				<span style="font-size: larger">Total Purchase: {{ total }}</span>
			</div>
			<vue-good-table
				id="dispatches"
				:columns="columns"
				:rows="rows"
				styleClass="vgt-table striped bordered"
				theme="black-rhino"
				max-height="700px"
				:fixed-header="true"
				:pagination-options="{
					enabled: true,
					mode: 'records',
					perPage: 10,
					position: 'bottom',
					perPageDropdown: [10, 15, 20],
					dropdownAllowAll: true,
					setCurrentPage: 1,
					jumpFirstOrLast: true,
					firstLabel: 'First',
					lastLabel: 'Last',
					nextLabel: 'next',
					prevLabel: 'prev',
					rowsPerPageLabel: 'Rows per page',
					ofLabel: 'of',
					pageLabel: 'page', // for 'pages' mode
					allLabel: 'All',
				}"
			>
				<template slot="table-row" slot-scope="props">
					<span v-if="props.column.field === 'amountExc'">
						<p class="text-bold text-red text-align-right">
							{{ props.row.amountExc }}
						</p>
					</span>
					<span v-else-if="props.column.field === 'amount'">
						<p class="text-bold text-blue text-align-right">
							{{ props.row.amount }}
						</p>
					</span>
					<span v-else>
						{{ props.formattedRow[props.column.field] }}
					</span>
				</template>
			</vue-good-table>
			<download-csv :data="rows">
				<div class="margin-bottom-30 margin-top-30">
					<b-button class="is-success"> Download the List </b-button>
				</div>
			</download-csv>
		</div>
	</layout-wide>
</template>
<script>
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar'
import {
	// computed,
	onMounted,
	reactive,
	ref,
	watchEffect,
} from '@vue/composition-api'
import dateformat from 'dateformat'
import numeral from 'numeral'
import Store from '@/store'
import GetTimeIntervalPurchaseInvoicesTotalsQuery from '@/_srcv2/pages/purchase-invoice/purchase-invoice-report/GetTimeIntervalPurchaseInvoicesTotalsQuery.graphql'
import { useQuery } from '@vue/apollo-composable'

export default {
	name: 'PurchaseInvoiceReport',
	components: {
		SubNavbar,
	},
	setup() {
		const parameters = reactive({
			beginningDate: '',
			endingDate: '',
		})
		// todo ---------------------------------------------------------------
		onMounted(() => {
			const date = new Date()
			selectedBeginningDate.value = new Date(
				date.getFullYear(),
				date.getMonth(),
				1,
			)
			selectedEndingDate.value = new Date(
				date.getFullYear(),
				date.getMonth() + 1,
				0,
			)
		})
		// todo ---------------------------------------------------------------
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { onResult, refetch, loading } = useQuery(
			GetTimeIntervalPurchaseInvoicesTotalsQuery,
			() => ({
				company_id: Store.getters.getUserCurrentCompany,
				bd: parameters.beginningDate,
				ed: parameters.endingDate,
			}),
			options,
		)
		// todo -----------------------------------------------------------
		const numberFromDataBaseToTable = (number, currency = 'kr.') => {
			return numeral(number).format('0,0.00') + ' ' + currency
		}

		const rows = ref([])

		const mapRows = (result) => {
			rows.value = result.data.document_transactions_aggregate.nodes.map(
				(item) => {
					let amountExcRaw =
						(item._purchase_invoice_totals_aggregate.aggregate.sum
							.line_price_total_debit_exchange +
							item._purchase_invoice_totals_aggregate.aggregate.sum
								.vat_debit_exchange) /
						100
					let amountRaw =
						(item._purchase_invoice_totals_aggregate.aggregate.sum
							.line_price_total_debit +
							item._purchase_invoice_totals_aggregate.aggregate.sum.vat_debit) /
						100
					let amountToReduce =
						item._purchase_invoice_totals_aggregate.aggregate.sum
							.line_price_total_debit +
						item._purchase_invoice_totals_aggregate.aggregate.sum.vat_debit
					console.log('amountToReduce', amountToReduce)
					return {
						invoiceNumber: item.invoice_number,
						invoiceDate: item.invoice_date,
						customer:
							item.customer.customer_title +
							' - ' +
							item.customer.customer_nickname,
						amountExc: numberFromDataBaseToTable(
							amountExcRaw,
							item.invoice_exchange_unit,
						),
						amount: numberFromDataBaseToTable(amountRaw),
						amountRaw: String(amountRaw).replace('.', ','),
						amountReduce: amountToReduce,
					}
				},
			)
		}
		onResult((result) => {
			mapRows(result)
			console.log('onResult rows.value', rows.value)
			total.value =
				numeral(
					rows.value.reduce(function (accumulator, item) {
						accumulator = accumulator + item.amountReduce
						console.log('accumulator', accumulator)
						return accumulator
					}, 0) / 100,
				).format('0,0.00') + ' kr.'
		})
		const getData = () => {
			refetch().then((result) => {
				mapRows(result)
				console.log('result', result)
				total.value =
					numeral(
						rows.value.reduce(function (accumulator, item) {
							accumulator = accumulator + item.amountReduce
							console.log('accumulator', accumulator)
							return accumulator
						}, 0) / 100,
					).format('0,0.00') + 'kr.'
			})
		}
		const total = ref(0)
		const selectedBeginningDate = ref()
		watchEffect(() => {
			parameters.beginningDate = dateformat(
				selectedBeginningDate.value,
				'yyyy-mm-dd',
			)
		})
		const selectedEndingDate = ref()
		watchEffect(() => {
			parameters.endingDate = dateformat(selectedEndingDate.value, 'yyyy-mm-dd')
		})
		const showWeekNumber = false
		const locale = 'sv-SE'
		// todo ----------------------------------------------------------------------
		const columns = [
			{
				label: 'Invoice Number',
				field: 'invoiceNumber',
				width: '250px',
			},

			{
				label: 'Invoice Date',
				field: 'invoiceDate',
				width: '125px',
			},
			{
				label: 'Customer',
				field: 'customer',
			},

			{
				label: 'Amount Exchange include VAT',
				field: 'amountExc',
				width: '185px',
			},

			{
				label: 'Amount include VAT',
				field: 'amount',
				width: '185px',
			},
		]
		return {
			selectedBeginningDate,
			selectedEndingDate,
			showWeekNumber,
			locale,
			getData,
			loading,
			columns,
			rows,
			// totalAmount,
			total,
		}
	},
}
</script>
<style scoped lang="scss">
.warning {
	color: red;
	font-size: large;
	font-weight: 600;
	margin-left: 200 px;
	margin-top: 200 px;
}
</style>
