var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout-wide',[_c('sub-navbar',{attrs:{"page":"Purchase Invoice"}}),_c('div',[_c('div',{staticClass:"form-title"},[_vm._v("Time Interval Purchase Invoices")]),_c('b-field',{staticClass:"margin-top-20 text-red",staticStyle:{"font-size":"larger","font-weight":"700"}},[_vm._v("Select beginning date or ending date and wait for results")]),_c('b-field',{staticClass:"margin-top-20",attrs:{"label":"Select beginning date"}},[_c('b-datepicker',{attrs:{"show-week-number":_vm.showWeekNumber,"locale":_vm.locale,"placeholder":"Click to select...","icon":"calendar-today","trap-focus":""},model:{value:(_vm.selectedBeginningDate),callback:function ($$v) {_vm.selectedBeginningDate=$$v},expression:"selectedBeginningDate"}})],1),_c('b-field',{attrs:{"label":"Select ending date"}},[_c('b-datepicker',{attrs:{"show-week-number":_vm.showWeekNumber,"locale":_vm.locale,"placeholder":"Click to select...","icon":"calendar-today","trap-focus":""},model:{value:(_vm.selectedEndingDate),callback:function ($$v) {_vm.selectedEndingDate=$$v},expression:"selectedEndingDate"}})],1)],1),(_vm.loading)?_c('div',{staticClass:"margin-top-20",staticStyle:{"color":"red","font-size":"large","font-weight":"600"}},[_vm._v(" Getting data, please wait! ")]):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"margin-bottom-30 margin-top-30"},[_c('div',{staticClass:"margin-bottom-30 text-bold text-blue"},[_c('span',{staticStyle:{"font-size":"larger"}},[_vm._v("Total Purchase: "+_vm._s(_vm.total))])]),_c('vue-good-table',{attrs:{"id":"dispatches","columns":_vm.columns,"rows":_vm.rows,"styleClass":"vgt-table striped bordered","theme":"black-rhino","max-height":"700px","fixed-header":true,"pagination-options":{
				enabled: true,
				mode: 'records',
				perPage: 10,
				position: 'bottom',
				perPageDropdown: [10, 15, 20],
				dropdownAllowAll: true,
				setCurrentPage: 1,
				jumpFirstOrLast: true,
				firstLabel: 'First',
				lastLabel: 'Last',
				nextLabel: 'next',
				prevLabel: 'prev',
				rowsPerPageLabel: 'Rows per page',
				ofLabel: 'of',
				pageLabel: 'page', // for 'pages' mode
				allLabel: 'All',
			}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'amountExc')?_c('span',[_c('p',{staticClass:"text-bold text-red text-align-right"},[_vm._v(" "+_vm._s(props.row.amountExc)+" ")])]):(props.column.field === 'amount')?_c('span',[_c('p',{staticClass:"text-bold text-blue text-align-right"},[_vm._v(" "+_vm._s(props.row.amount)+" ")])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,173502653)}),_c('download-csv',{attrs:{"data":_vm.rows}},[_c('div',{staticClass:"margin-bottom-30 margin-top-30"},[_c('b-button',{staticClass:"is-success"},[_vm._v(" Download the List ")])],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }